:root {
  --fc-border-color: black;
  --fc-border-width: 2px;
  --fc-daygrid-event-dot-width: 5px;
}
@media only screen and (max-width: 992px) {
  /* Header part */
  button.fc-today-button.fc-button.fc-button-primary {
    width: 100%;
  }
  button.fc-backButton-button{
    width: 100% !important;
  }
  .fc .fc-button-group{
    display: flex !important;
    margin: 8px 0;
  }
  .fc .fc-button-group .fc-button{
    width: 50%;
  }
  .app-booking-page-main .fc .fc-toolbar.fc-header-toolbar{
    display: inline !important;
  }
  .fc-toolbar-title{
    font-size: 16px !important;
    text-align: center;
    margin: 8px !important;
  }
  /* Token image part */
  .img-tokenIcon{
    height: 24px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .fc-timegrid-event .fc-event-time {
    white-space: normal;
  }
  .app-booking-page-main {
    /* min-width: 1024px;
    max-height: 576px; */
  }
  .app-booking-page-main>div {
    height: 576px;
  }
  .app-booking-page-main .fc-col-header-cell.fc-day, .fc-header-toolbar {
      padding: 0px 2px !important;
  }
  .app-booking-page-main .fc-scrollgrid-sync-inner {
      padding: 2px !important;
  }
  .app-booking-page-main .fc .fc-col-header-cell-cushion{
      font-size:12px;
  }
  .booking-event {
      /* font-size:8px; */
  }
  .app-booking-page-main a.fc-event {
      padding: 12px 0 !important;
  }
  .app-booking-page-main .fc-direction-ltr .fc-timegrid-col-events {
      margin: 0px !important;
  }
  .app-booking-page-main .fc-timegrid-event-harness {
      margin: 4px 0px !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .fc-timegrid-event .fc-event-time {
    white-space: normal;
  }
  .app-booking-page-main {
    /* min-width: 400px;
    max-height: 576px; */
  }
  .app-booking-page-main>div {
    height: 576px;
  }
  .app-booking-page-main .fc-col-header-cell.fc-day, .fc-header-toolbar {
      padding: 0px 2px !important;
  }
  .app-booking-page-main .fc-scrollgrid-sync-inner {
      padding: 12px 0 !important;
  }
  .app-booking-page-main .fc .fc-col-header-cell-cushion{
      /* font-size:14px; */
  }
  .booking-event {
      /* font-size:10px; */
  }
  .app-booking-page-main .fc-timegrid-event-harness {
      margin: 8px 0px !important;
  }
  .app-booking-page-main a.fc-event {
      padding: 12px !important;
  }
  .app-booking-page-main .fc-direction-ltr .fc-timegrid-col-events {
      margin: 8px !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .fc-timegrid-event .fc-event-time {
    white-space: normal;
  }
  .app-booking-page-main {
    /* min-width: 600px;
    max-height: 576px; */
  }
  .app-booking-page-main>div {
    height: 576px;
  }
  .app-booking-page-main .fc-col-header-cell.fc-day, .fc-header-toolbar {
      padding: 0px 2px !important;
  }
  .app-booking-page-main .fc-scrollgrid-sync-inner {
      padding: 2px !important;
  }
  .app-booking-page-main .fc .fc-col-header-cell-cushion{
      font-size:16px;
  }
  .booking-event {
      font-size:14px;
  }
  .app-booking-page-main .fc-timegrid-event-harness {
      margin: 8px 0px !important;
  }
  .app-booking-page-main a.fc-event {
      padding: 12px !important;
  }
  .app-booking-page-main .fc-direction-ltr .fc-timegrid-col-events {
      margin: 8px !important;
  }
  /* Token image part */
  .img-tokenIcon{
    height: 36px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .app-booking-page-main {
    /* min-width: 768px;
    max-height: 576px; */
  }
  .app-booking-page-main>div {
    height: 576px;
  }
  .app-booking-page-main .fc-button-group {
      gap: 16px;
  }
  .app-booking-page-main button.fc-button.fc-button-primary {
      width: 117px;
  }
  .app-booking-page-main .fc-col-header-cell.fc-day, .fc-header-toolbar {
      padding: 0px 8px !important;
  }
  .app-booking-page-main .fc-scrollgrid-sync-inner {
      padding: 6px !important;
  }
  .app-booking-page-main .fc-timegrid-event-harness {
      margin: 8px 0px !important;
  }
  .app-booking-page-main a.fc-event {
      padding: 12px !important;
  }
  .app-booking-page-main .fc-direction-ltr .fc-timegrid-col-events {
      margin: 8px !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .app-booking-page-main {
    /* min-width: 1024px;
    max-height: 576px; */
  }
  .app-booking-page-main>div {
    height: 576px;
  }
  .app-booking-page-main .fc-toolbar-title {
      margin-right: 168px;
  }
  .app-booking-page-main .fc-button-group {
      gap: 16px;
  }
  .app-booking-page-main button.fc-button.fc-button-primary {
      width: 143px;
  }
  .app-booking-page-main .btn-booking-event
  {
    font-size: 14px;
    width: 46px;
  }
  .app-booking-page-main .fc-col-header-cell.fc-day, .fc-header-toolbar {
      padding: 0px 8px !important;
  }
  .app-booking-page-main .fc-scrollgrid-sync-inner {
      padding: 6px !important;
  }
  .app-booking-page-main .fc-timegrid-event-harness {
      margin: 8px 0px !important;
  }
  .app-booking-page-main a.fc-event {
      padding: 12px !important;
  }
  .app-booking-page-main .fc-direction-ltr .fc-timegrid-col-events {
      margin: 8px !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
  .app-booking-page-main {
    /* min-width: 1320px;
    max-height: 576px; */
  }
  .app-booking-page-main>div {
    height: 576px;
  }
  .app-booking-page-main .fc-toolbar-title {
      margin-right: 210px;
  }
  .app-booking-page-main .fc-button-group {
      gap: 16px;
  }
  .app-booking-page-main button.fc-button.fc-button-primary {
      width: 169px;
  }
  .app-booking-page-main .btn-booking-event
  {
    font-size: medium;
    width: 60px;
  }
  .app-booking-page-main .fc-col-header-cell.fc-day, .fc-header-toolbar {
      padding: 0px 8px !important;
  }
  .app-booking-page-main .fc-scrollgrid-sync-inner {
      padding: 6px !important;
  }
  .app-booking-page-main .fc-timegrid-event-harness {
      margin: 8px 0px !important;
  }
  .app-booking-page-main a.fc-event {
      padding: 12px !important;
  }
  .app-booking-page-main .fc-direction-ltr .fc-timegrid-col-events {
      margin: 8px !important;
  }
}
.app-booking-page-main .fc .fc-toolbar.fc-header-toolbar{
  /* padding: 0 8px; */
}

/* text - left column! */
.app-booking-page-main .fc .fc-timegrid-axis-cushion,.app-booking-page-main  .fc .fc-timegrid-slot-label-cushion {
  color:black;
}

/* header - first row*/
.app-booking-page-main .fc .fc-col-header-cell-cushion {
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    text-decoration: none;
}
.app-booking-page-main .fc-toolbar-title {
    color: white;
    font-weight: 800;
}
/* header - first row*/
/* .app-rental-page-main th,.app-booking-page-main .app-rental-page-main th>.fc-scrollgrid-sync-inner {
    background: #262A34;
} */

/* button */
.app-booking-page-main .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
    border-radius: 20px !important;
}
.app-booking-page-main .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
    border-radius: 20px !important;
    margin-left: -1px !important;
}
.app-booking-page-main .fc .fc-button {
    -webkit-appearance: button;
    border-radius: 20px !important;
    padding: 8px 24px !important;
}
/* each timeslot - custom style */
.app-booking-page-main .fc-event.fc-event-start.fc-event-end.fc-event-past.fc-timegrid-event.fc-v-event {
    box-shadow: none !important;
}
.app-booking-page-main .fc-timegrid-event-harness-inset .fc-timegrid-event,.app-booking-page-main  .fc-timegrid-event.fc-event-mirror,.app-booking-page-main  .fc-timegrid-more-link{
    box-shadow: none !important;
}
.app-booking-page-main .fc-timegrid-event .fc-event-time .fc-event-title .fc-sticky {
    font-size: 12px !important;
    font-weight: bold !important;
}
.app-booking-page-main .fc .fc-timegrid-axis-cushion,.app-booking-page-main  .fc .fc-timegrid-slot-label-cushion {
    color: #000 !important;
    font-weight: bold !important;
}
.app-booking-page-main .fc-scrollgrid-sync-inner {
    border-radius: 28px !important;
    background: #262a34 !important;
}
.fc-button{
    background: #262a34 !important;
}
.app-booking-page-main .fc .fc-timegrid-slot .fc-timegrid-slot-label .fc-timegrid-slot-minor{
    display: none !important;
}
/* today */
.fc .fc-timegrid-col.fc-day-today{
  background: none !important;
}
.app-booking-page-main .fc-media-screen .fc-timegrid-cols>table {
    height: 100% !important;
}
/* each event */
.app-booking-page-main .fc-theme-standard td,.app-booking-page-main  .fc-theme-standard th {
    border: 0 !important;
}
.app-booking-page-main .fc-timegrid-event-harness {
    position: initial !important;
    visibility: unset !important;
}
.app-booking-page-main a.fc-event{
    position: initial !important;
    background: white !important;
    border-radius: 32px !important;
    border: 2px solid !important;
    border-color: #181a1f !important;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3) !important;
}
.app-booking-page-main .fc-v-event .fc-event-main{
    color: black !important;
}
.app-booking-page-main .fc-timegrid-event .fc-event-main {
    /* height: 200px !important; */
}
.app-booking-page-main .fc-event-main-frame {
    margin: 8px !important;
}
.app-booking-page-main .fc-theme-standard .fc-scrollgrid {
    border: 0 !important;
}

.app-booking-page-main .fc-timegrid-slots {
    display: none;
}
.app-booking-page-main tr.fc-scrollgrid-section-body:nth-of-type(1),.app-booking-page-main  tr.fc-scrollgrid-section:nth-of-type(2) {
    display: none;
}
.bold-horizen-line {
    color: black;
    border-top: 2px black solid;
}
.app-booking-page-main .btn-booking-event
{
  align-items: center;
  /* border: black 2px solid; */
  border-radius: 60px;
  display: inline-flex;
  float: center;
  font-weight: 900;
  height: 36px;
  width: 72%;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 2px;
}
.app-booking-page-main .btn-booking-event-details
{
  background: #181a1f;
  color: white;
}
.app-booking-page-main .btn-booking-event-booking
{
  background: #8e96ff;
  color: white;
}
.app-booking-page-main button.btn-booking-event-booking:disabled,
.app-booking-page-main button.btn-booking-event-booking[disabled]{
  background: grey;
  color: darkgrey;
  border-color: grey;
}
.app-booking-page-main .booking-event{
  text-align: center;
}
table.fc-col-header{
  width: 100% !important;
}
.fc-theme-standard td, .fc-theme-standard th{
  border-width: 2px !important;
}
