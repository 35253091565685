body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, div, p, h2 {
  /* font-family: Phosphate !important; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (max-width: 992px){
  body, html {
    overflow-y: auto !important;
  }
  .app-wrapper{
    height: fit-content !important;
    overflow: inherit !important;
  }
  .app-page {
    top: 0px !important;
  }
  body, div#root, html {
    height: 100% !important;
  }
}
