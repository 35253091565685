.app-wrapper {
  /* display: flex; */
  justify-content: center;
  align-items: start ;
  float: center;
  background-color: #ffFFff;
  /* position: absolute; */
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

.app-page {
  /* display: grid; */
  justify-content: center;
  align-items: center;
  float: center;
  position: relative;
  top: 44px;
}
.app-top {
    /* padding-left: 36px;
    padding-right: 36px; */
}
.app-logo {
  /* display: grid; */
  justify-content: center;
  align-items: center;
  position: flex;
  left: 0px;
  top: 44px;
  margin-top: 36px;
  min-height: 120px;
}

.app-logo-img {
  max-width: 240px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: auto;
  padding: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.app-container-text{
  font-size: 36px;
  font-weight: 800;
  color: #181a1f;
  text-align: center;
}
.app-page-form {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: medium;
  font-weight: bold;
  position: relative;
  width: 100%;
  height: 320px;
}
.app-page-form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: medium;
  font-weight: bold;
  position: relative;
  width: 100%;
  height: 120px;
}

.app-page-form-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  color: white;
}
.app-page-form-block>div{
  background: white;
  border: 2px solid;
  border-color: black;
  border-radius: 60px;
  padding: 0 8px;
  width: 100%;
  height: 48px;
}
.app-setting {
  display: grid;
  justify-content: center;
  align-items: center;
  float: center;
  margin-top: 10px;
}

.app-setting-content {
  width: 200px;
}

.app-setting-content-left {
  display: inline-flex;
  justify-content: left;
  align-items: left;
  float: left;
  font-size: 14px;
}

.app-setting-content-right {
  display: inline-flex;
  justify-content: right;
  align-items: right;
  float: right;
  font-size: 14px;
}

.app-bottom {
  justify-content: center;
  align-items: center;
}

.app-bottom-container {
  padding-top: 30px;
}

.app-bottom-container-submit {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: rgb(142, 150, 255);
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}

.app-bottom-container-register {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: #000000;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}

.app-bottom-container-back {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: #3a3a3a;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}
.app-bottom-container-booking{
  margin-top: 12px;
}
.app-bottom-container-booking-back{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: #3a3a3a;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}
.app-bottom-container-booking-payment{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: black;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}
.app-bottom-container-booking-text{
  color: #181a1f;
  font-weight: bold;
  text-align: center;
}
.app-bottom-register-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  float: center;
  padding-top: 15px;
}

.app-bottom-register-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  background-color: inherit;
  border: 0px;
  color: #B47D64;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 200px;
  font-size: 14px;
  font-weight: bold;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: while !important;
  opacity: 1 !important; /* Firefox */
}
.app-payment-container{
}
.app-payment-container-visa{
  background: #262a34;
}
.app-payment-container-fps{
  background: white;
}
.app-payment-container-payme{
  background: #db0011;
}
.app-payment-container-token{
  background: #181a20;
}
.transition-modal-title{

}
.transition-modal-description{

}
.modal-bottom-button{
  width: 100%;
  border-radius: 32px;
  height: 48px;
  margin-top: 12px;
}
.white-button{
  background: white;
  color: black;
  border: 1px solid black;
}
.black-button{
  background: black;
  color: white;
  border: 1px solid black;
}
.app-payment-success-container{
  display: grid;
  align-content: stretch;
  justify-content: center;
  align-items: end;
  justify-items: center;
}
.app-payment-success-img{
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 140px;
  overflow: hidden;
  padding: 0;
}
.app-payment-success-text{
  margin-top: 12px;
  font-size: 36px;
  color: #181a1f;
  text-align: center;
}
