:root {
  --fc-border-color: black;
  --fc-border-width: 2px;
  --fc-daygrid-event-dot-width: 5px;
}
/* text - left column! */
.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
  color:black;
}

/* header - first row*/
.fc .fc-col-header-cell-cushion {
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    text-decoration: none;
}
.fc-toolbar-title{
  display: none;
  color: white!important;
  font-family: auto;
  font-size: 38px !important;
  border-bottom: 3px solid;
  padding: 0px 16px;
}
/* header - first row*/
.app-rental-page-main th,.app-rental-page-main th>.fc-scrollgrid-sync-inner {
    background: #262A34;
}

.app-rental-page-main {
  min-width: 100%;
  max-height: 576px;
}
.app-rental-page-form .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}
.app-rental-page-main>div {
    height: 576px;
}
/* button */
.fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-left: -1px !important;
}
.fc .fc-button {
    -webkit-appearance: button;
    border-radius: 20px !important;
    padding: 8px 24px !important;
}
/* each timeslot - custom style */
.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-timegrid-event.fc-v-event {
    box-shadow: none !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link{
    box-shadow: none !important;
}
.fc-timegrid-event .fc-event-time .fc-event-title .fc-sticky {
    font-size: 12px !important;
    font-weight: bold !important;
}
.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
    color: #000 !important;
    font-weight: bold !important;
}
/*CSS For modal*/
#transition-modal-title {
    font-size: 28px;
    font-weight: 900;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app-rental-page-main {
    /* min-width: 1024px;
    max-height: 576px; */
  }
  .app-rental-page-main>div {
    height: 576px;
  }
  .app-rental-page-main .fc-col-header-cell.fc-day {
      padding: 0px 2px !important;
  }
  .app-rental-page-main .fc-scrollgrid-sync-inner {
      padding: 0px !important;
      font-size: 12px;
  }
  .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
      font-size: 12px;
  }
  .app-rental-page-main .fc .fc-col-header-cell-cushion{
      font-size:12px;
  }
  .app-rental-page-main a.fc-event.fc-event-future.fc-timegrid-event.fc-v-event,
  .app-rental-page-main a.fc-event.fc-event-today.fc-timegrid-event.fc-v-event,
  .app-rental-page-main a.fc-event.fc-event-past.fc-timegrid-event.fc-v-event {
      /* padding: 12px 0 !important; */
  }
  .app-rental-page-main .fc-direction-ltr .fc-timegrid-col-events {
      margin: 0px !important;
  }
  .app-rental-page-main .fc-timegrid-event-harness {
      /* margin: 4px 0px !important; */
  }
  .fc-toolbar-title{
    font-size: 16px !important;
    text-align: center;
    margin: 8px !important;
  }
}
@media only screen and (max-width: 768px) {
  /* Header part */
  button.fc-today-button.fc-button.fc-button-primary {
    width: 100%;
    margin: 0 !important;
  }
  button.fc-backButton-button{
    width: 100% !important;
  }
  .fc .fc-button-group{
    display: flex !important;
  }
  .app-rental-page-main .fc .fc-toolbar.fc-header-toolbar{
    display: inline !important;
  }
  .fc-toolbar-title{
    font-size: 16px !important;
    text-align: center;
    margin: 8px !important;
  }
}
@media only screen and (max-width: 992px) {
  .fc .fc-timegrid-slot {
    height: 2.5em;
  }
}
