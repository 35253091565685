.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.app-page-bottom{
  margin-bottom: 16px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html,
body {
  background: #ffFFff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
}
div#root{
  background: #ffFFff;
  width: 100vw;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

.component-hidden{
  display: none;
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container{
    min-width: 768px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container{
    min-width: 992px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
  .container{
    min-width: 1200px;
  }
}
